<template>
  <div>
    <ul style="padding-bottom:20px">
      <div class="imgs" v-for="item in imgs" :key="item.name">
        <div>
          <img :src="item.path" alt="" srcset="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          name: "2018年5月8日，省信访局副局长邵根宝莅临中心视察指导，台州市委副秘书长、信访局长朱良其，时任区委副书记包顺富陪同视察。",
          // path: require("../../assets/img/lunbo.png"),
          path:this.$url+"/static/img/lunbo.png",
        },
        {
          name: "2018年5月22日，省信访局副局长王宏莅临中心视察指导，台州市委副秘书长、信访局长朱良其，市信访局副局长、市12345政务咨询投诉举报中心主任林健刚，区人大常委会主任王国平等领导陪同视察。",
          path:this.$url+"/static/img/lunbo1.png",
        },
        {
          name: "2018年5月25日，丽水市莲都区政协考察团在区政协副主席虞为粮带领下莅临中心参观考察，椒江区政协副主席王志英陪同参观。",
          path:this.$url+"/static/img/lunbo2.png",
        },
        {
          name: "    2018年7月18日，温州乐清市考察团在时任市委办副主任吴天峰带领下莅临中心参观考察，时任区统一政务咨询投诉举报平台管理办公室主任褚高峰陪同参观。",
          path:this.$url+"/static/img/lunbo3.png",
        },
        {
          name: "  2018年8月8日，嘉兴市嘉善县政协考察团在政协副主席陆才华带领下莅临中心参观考察，椒江区政协副主席王志英陪同参观。",
          path:this.$url+"/static/img/lunbo4.png",
        },
        {
          name: " 2018年11月20日，时任中共台州市委书记陈奕君在时任椒江区委书记陈挺晨的陪同莅临中心视察指导。",
          path:this.$url+"/static/img/lunbo5.png",
        },
        {
          name: "2020年4月2日，台州市委副书记、政法委书记芮宏一行莅临中心视察指导工作，台州市委政法委副书记林喜军，时任椒江区委书记陈挺晨，区委常委、常务副区长王智承等领导陪同。",
          path:this.$url+"/static/img/lunbo6.png",
        },
        {
          name: "2020年7月11日，山东省政府副秘书长、省信访局局长范晓丽一行，福建省信访局副局长范志平一行来我区调研社会矛盾纠纷调处化解中心建设工作，浙江省信访局副局长邵根宝，台州市信访局局长朱良其，椒江区委常委、常务副区长王智承陪同。",
          path:this.$url+"/static/img/lunbo7.png",
        },
        {
          name: " 2020年7月14日，中共广东海丰县委副书记、政法委书记李绪一行来我区社会矛盾纠纷调处化解中心考察，区委常委、常务副区长王智承陪同。",
          path:this.$url+"/static/img/lunbo8.png",
        },
        {
          name: "2020年7月27日，浙江省高级人民法院副院长何鉴伟莅临中心视察指导，椒江区委常委、常务副区长王智承，椒江区人民法院院长陈艳陪同。",
          path:this.$url+"/static/img/lunbo9.png",
        },
        {
          name: "2020年8月4日，中共台州市椒江区委副书记、政法委书记马骏调研我区矛调中心建设情况，相关部门主要负责人陪同调研。",
          path:this.$url+"/static/img/lunbo10.png",
        },
        {
          name: "2020年8月13日，时任椒江区人大常委会副主任缪信权一行来矛调中心视察调研，相关部门负责人陪同。",
          path:this.$url+"/static/img/lunbo11.png",
        },
        {
          name: "    2020年8月20日，九三学社台州市委主委何晓霞一行调研我区矛调中心建设情况，相关部门负责人陪同调研。",
          path:this.$url+"/static/img/lunbo12.png",
        },
        {
          name: "  2020年8月21日，贵州省桐梓县政务服务改革考察团参观社会矛盾纠纷调处化解中心，区委常委、常务副区长王智承陪同。",
          path:this.$url+"/static/img/lunbo13.png",
        },
        {
          name: " 2020年9月3日，江苏无锡考察团参观我区社会矛盾纠纷调处化解中心，相关部门主要负责人陪同。",
          path:this.$url+"/static/img/lunbo14.png",
        },
        {
          name: "2020年9月11日，台州市人大常委会副主任赵跃进一行来中心调研我区“最多跑一地”改革工作，椒江区委副书记、政法委书记马骏，椒江区人大常委会党组成员李伟兴陪同调研。",
          path:this.$url+"/static/img/lunbo15.png",
        },
        {
          name: " 2020年9月15日，省人大常委会副主任姒健敏一行莅临区矛调中心调研我区 “最多跑一地”改革工作，市人大常委会副主任林金荣、区人大常委会主任王国平、区委副书记、政法委书记马骏等市、区两级领导陪同调研。",
          path:this.$url+"/static/img/lunbo16.png",
        },
        {
          name: " 2020年10月20日上午，省委依法治省办法治督察组莅临区矛调中心专项督察矛调中心规范化建设情况，省委政法委副书记沈智深带队，时任台州市委常委、常务副市长蔡永波，椒江区委副书记、政法委书记马骏陪同督察",
          path:this.$url+"/static/img/lunbo17.png",
        },
        {
          name: " 2020年10月30日，台州市中青年干部培训班矛调中心建设课题组到中心调研，相关部门主要负责人陪同。",
          path:this.$url+"/static/img/lunbo18.png",
        },
        {
          name: "    2020年11月9日，四川广元市基层信访治理培训班学员到中心调研，相关部门主要负责人陪同",
          path:this.$url+"/static/img/lunbo19.png",
        },
        {
          name: "  2020年11月11日，浙江《法制日报》总编一行调研区矛调中心，相关部门主要负责人陪同。",
          path:this.$url+"/static/img/lunbo20.png",
        },
        {
          name: " 2020年12月15日，浙江省副省长朱从玖一行莅临区矛调中心检查指导，时任椒江区区委书记陈挺晨，椒江区委副书记、政法委书记马骏陪同。",
          path:this.$url+"/static/img/lunbo21.png",
        },
        {
          name: "2021年1月13日，安徽省池州市司法局一行到矛调中心参观考察，相关单位负责人陪同。",
          path:this.$url+"/static/img/lunbo22.png",
        },
        {
          name: "2021年2月23日，台州市副市长、市公安局局长朱建军一行莅临区矛调中心开展全国市域社会治理现代化试点工作“三服务”活动，椒江区委副书记、政法委书记马骏，椒江区委常委、区公安局长朱怀宏陪同。",
          path:this.$url+"/static/img/lunbo23.png",
        },
        {
          name: "  2021年3月9日，浙江省平安建设考核组到区矛调中心进行平安建设考核，市政法委相关领导和区相关部门负责人陪同。",
          path:this.$url+"/static/img/lunbo24.png",
        },
        {
          name: "2021年3月13日，中国社会科学院副所长一行到区矛调中心进行调研，相关部门负责人陪同。",
          path:this.$url+"/static/img/lunbo25.png",
        },
        {
          name: "2021年3月17日，天台县矛调中心常务副主任带队到区矛调中心进行考察，相关部门负责人陪同。",
          path:this.$url+"/static/img/lunbo26.png",
        },
        {
          name: "   2021年3月27日，云南省大理州政法系统考察团一行到区矛调中心参观考察，市人大常委会副主任林金荣，市政法委常务副书记李晏子，副书记林喜军陪同。",
          path:this.$url+"/static/img/lunbo27.png",
        },
      ],
    };
  },
};
</script>
<style scoped>
.imgs {
  padding: 0 20px;
  height: auto;
  margin-top: 20px;
}

/* .imgs:last-child {
  margin-bottom: 20px;
} */

.imgs div {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 6px 3px #c9c9c9;
}

.imgs img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.imgs p {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 15px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>